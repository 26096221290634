import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '480'
  },
  xl: {
    height: '530'
  }
}

const FundingAccessibleLoan = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "funding-arrangements/accessible-loan.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      imageData={placeholderImage}
      backgroundColor='veryLightOrange'>
      <HomeSectionTitle tag='h3'>
        Les prêts d’aide à l’accession : les financements immo pour l’immobilier neuf
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        La deuxième famille des types de financement immo sont les prêts d’aide à l’accession.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Ces emprunts sont particulièrement adaptés si vous souhaitez investir dans l’immobilier neuf. Par ailleurs, investir dans l’immobilier neuf comporte de nombreux avantages : frais de notaire réduits à 2%, aucun frais d’agence. Zoom sur les prêts d’aide à l’accession.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default FundingAccessibleLoan
