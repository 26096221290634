import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 72px;
  padding-bottom: 72px;
  flex-direction: column;
  background-color: ${({ theme }) => get(theme, 'paleGrey', '#FFF')};
  padding-right: 24px;
  padding-left: 24px;
`

const Content = styled.div`
  max-width: 790px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledButton = styled(LaunchButton)`
  margin-top: 15px;

  ${media.greaterThan('md')`
    min-width: 395px;
  `}
`

const FundingAgreedLoan = () => (
  <Container>
    <Content>
      <HomeSectionTitle>
        Opter pour le prêt conventionné (PC) comme financement&nbsp;immo
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Le prêt conventionné est également considéré comme un prêt d’accession.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Contrairement au PAS, le prêt conventionné n’est pas soumis à des conditions de ressources. Par ailleurs, le taux du prêt conventionné ne peut pas dépasser le plafond déterminé par décret.
      </HomeSectionDescription>
      <br />
      <HomeSectionDescription>
        L’avantage de ce financement immo, c’est qu’il ouvre également des droits à l’APL. Comme tous les prêts d’aide à l’accession, le PC peut être complété par d’autres crédits, tels que le PTZ, PAL, PEL ou encore le prêt relais.
      </HomeSectionDescription>
      <br />
      <HomeSectionDescription>
        <strong>En outre, le PC combiné avec l’achat d’un bien immobilier neuf permet de profiter des autres avantages</strong>, à savoir les frais de notaire réduits et les frais d’agence exonérés. S’il s’agit de votre premier achat, investir dans un bien neuf vous permet donc de faire des économies considérables.
      </HomeSectionDescription>
    </Content>
    <ButtonContainer>
      <StyledButton
        link='/services/emprunt-immobilier'
        title='Contactez-nous pour votre projet immobilier neuf'
        background='brightOrange' />
    </ButtonContainer>
  </Container>
)

export default FundingAgreedLoan
