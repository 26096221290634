import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import PictoEasy from '../../../assets/easy.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import PictoPercentageHouse from '../../../assets/percentage-house.inline.svg'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoPercentageHouse,
  title: 'Pour le prêt relais, l’établissement bancaire peut vous prêter entre 70 et 80% du prix de votre bien immobilier.'
}]

const DETAILS_COL_2 = [{
  icon: PictoEasy,
  description: 'Les conditions pour l’obtention du prêt relais sont similaires à celles d’un prêt classique&nbsp;: les banques sont mêmes <strong>plus souples pour accorder un prêt relais</strong> avec la possibilité d’avoir un taux d’endettement de 38%.'
}]

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 72px;
  padding-bottom: 72px;
  background-color: ${({ theme }) => get(theme, 'paleGrey', '#FFF')};
  padding-right: 24px;
  padding-left: 24px;
`

const Content = styled.div`
  max-width: 790px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const FundingBridgingLoan = () => (
  <Container>
    <Content>
      <HomeSectionTitle>
        Le prêt relais, qu’est-ce que c’est&nbsp;?
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Le prêt relais est un crédit non amortissable de courte durée, remboursable sur une durée de un à&nbsp;deux&nbsp;ans.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Ce type de financement immo se rembourse en très peu de temps, <strong>à la suite de la vente d’un autre bien immobilier</strong>. Autrement dit, le prêt relais est idéal dans le cas où vous souhaitez changer de logement, il permet de faire la transition en douceur entre vos deux transactions.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
      <HomeSectionDescription>
        À noter que le prêt relais peut être couplé avec un emprunt classique pour pouvoir bénéficier d’un taux d’emprunt semblable à celui d’un crédit immobilier lambda.
      </HomeSectionDescription>
      <br />
      <HomeSectionDescription>
        <strong>Le prêt relais n’est à rembourser qu’une fois que votre bien immobilier a été vendu</strong>, en gardant à l’esprit que le capital du prêt relais doit être soldé à la vente de votre bien.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL_2}/>
    </Content>
  </Container>
)

export default FundingBridgingLoan
