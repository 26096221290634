import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { get, map } from 'lodash'

import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'

const LINKS = [{
  title: 'Le prêt amortissable',
  link: '/principaux-modes-financement/#pret-amortissable-classique'
}, {
  title: 'Le prêt relais',
  link: '/principaux-modes-financement/#pret-relais'
}, {
  title: 'Le prêt <i>In Fine</i>',
  link: '/principaux-modes-financement/#pret-in-fine'
}]

const Container = styled.div`
  max-width: 620px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-right: 24px;
  padding-left: 24px;
`

const StyledLink = styled(Link)`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px auto;
  padding: 11px 10px;
  border-radius: 25px;
  background-color: ${({ theme }) => get(theme, 'paleBlue', '#FFF')};
  text-decoration: none;
`

const LoanText = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: ${({ theme }) => get(theme, 'blue', '#FFF')};

  & i {
    font-style: italic;
  }
`

const Links = () => map(LINKS, ({ title, link }) => (
  <StyledLink key={`${title}-${link}`} to={link} title={title}>
    <LoanText dangerouslySetInnerHTML={{ __html: title }} />
  </StyledLink>
))

const FundingLoans = () => (
  <Container>
    <HomeSectionTitle>
      Les prêts immobiliers bancaires
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      La première catégorie de financements immo sont les prêts bancaires. Actuellement, il y a trois types de prêts immobiliers :
    </HomeSectionSubtitle>
    <Links />
  </Container>
)

export default FundingLoans
