import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 72px;
  padding-bottom: 72px;
  padding-right: 24px;
  padding-left: 24px;
`

const Content = styled.div`
  max-width: 790px;
  display: flex;
  flex-direction: column;
`

const ImageBloc = styled.div `
  margin: 40px auto;
`

const FundingDepreciableLoanSecond = () => (
  <Container>
    <Content>
      <HomeSectionTitle>
        Les intérêts du prêt amortissable diminuent au fur et à mesure des années
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Les intérêts sont proportionnels au montant restant à rembourser.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        En d’autres termes, vous commencez à rembourser les intérêts avant de payer le capital de votre emprunt. Le montant est variable en fonction de la somme totale de votre emprunt.
      </HomeSectionDescription>
      <ImageBloc>
        <StaticImage
          src='../../../images/funding-arrangements/depreciable-loan.png'
          alt='Les intérêts du prêt amortissable diminuent au fur et à mesure des années'
          fadeIn={false} 
          loading='eager'
          width='500' />
      </ImageBloc>
      <HomeSectionDescription>
        À titre d’information, les intérêts du prêt amortissable représentent la plupart du temps jusqu’à presque 50% de la totalité de l’emprunt. Les conditions pour obtenir ce prêt sont assez classiques, avec la presque obligation de fournir 10% d’apport personnel.
      </HomeSectionDescription>
    </Content>
  </Container>
)

export default FundingDepreciableLoanSecond

