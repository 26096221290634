import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 72px;
  padding-bottom: 72px;
  background-color: ${({ theme }) => get(theme, 'paleGrey', '#FFF')};
  padding-right: 24px;
  padding-left: 24px;
`

const Content = styled.div`
  max-width: 790px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const FundingDepreciableLoanFirst = () => (
  <Container>
    <Content>
      <HomeSectionTitle>
        Le prêt amortissable, le financement immo le plus classique
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Le prêt amortissable est celui le plus courant lorsqu’il s’agit d’investir dans de l’immobilier.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Ce type de financement immo permet aux emprunteurs de rembourser le capital de leur emprunt sur une durée déterminée, étalée sur plusieurs années.
      </HomeSectionDescription>
    </Content>
  </Container>
)

export default FundingDepreciableLoanFirst
