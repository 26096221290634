import React from 'react'

import getMediaSize from '../../../utils/getMediaSize'
import useWindowSize from '../../../utils/useWindowSize'

const LineBreak = () => {
  const { width } = useWindowSize()
  const isDesktop = width > getMediaSize('xl')

  if (isDesktop) {
    return (
      <br />
    )
  }

  return null
}

export default LineBreak
