import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const FundingIntro = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "funding-arrangements/phone-image.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      fixed={true}
      imageData={placeholderImage}
      backgroundColor='white'>
      <HomeSectionTitle tag='h2'>
        Les différents types de financement pour un achat immobilier
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Le financement d’un projet immobilier se réfère plus concrètement aux prêts immobiliers qu’il est possible d’obtenir dans le cadre d’un achat immobilier.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        À ce titre, il existe deux grandes familles de financements pour l’achat d’une maison ou d’un appartement : les prêts immobiliers bancaires et les prêts d’aide à l’accession. Zoom sur les différents types de financement pour votre achat immobilier.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default FundingIntro
