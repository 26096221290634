import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import TopSection from '../../molecules/TopSection'
import FundingImage from '../../molecules/FundingImage'
import FundingHero from '../../molecules/FundingHero'

const StyledImage = styled(FundingImage)`
  display: none;

  ${media.greaterThan('xl')`
    display: block;
  `}
`

const FundingTopSection = () => (
  <TopSection component={StyledImage} absoluteImage={false}>
    <FundingHero />
  </TopSection>
)

export default FundingTopSection
