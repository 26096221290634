import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import FundingPTZ from '../components/organisms/FundingPTZ'
import SiteSection from '../components/atoms/SiteSection/'
import FundingLoans from '../components/organisms/FundingLoans'
import FundingIntro from '../components/organisms/FundingIntro'
import FundingFolhomee from '../components/organisms/FundingFolhomee'
import FundingTopSection from '../components/organisms/FundingTopSection'
import FundingAgreedLoan from '../components/organisms/FundingAgreedLoan'
import FundingInFineLoan from '../components/organisms/FundingInFineLoan'
import FundingBridgingLoan from '../components/organisms/FundingBridgingLoan'
import FundingAccessibleLoan from '../components/organisms/FundingAccessibleLoan'
import FundingSocialAccessLoan from '../components/organisms/FundingSocialAccessLoan'
import FundingDepreciableLoanFirst from '../components/organisms/FundingDepreciableLoanFirst'
import FundingDepreciableLoanSecond from '../components/organisms/FundingDepreciableLoanSecond'

const TITLE = 'Quels sont les principaux modes de financement immobilier ?'
const DESCRIPTION = 'Les modes de financements immobiliers sont nombreux pour un investissement : retour sur les plus répandus.'

const LastSiteSection = styled(SiteSection)`
  margin-bottom: 0;
`

const FundingArrangements = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <FundingTopSection />
    <SiteSection
      id='financement-immobilier'
      $first={true}>
      <FundingIntro />
    </SiteSection>
    <SiteSection id='prets-immobiliers-bancaires'>
      <FundingLoans />
    </SiteSection>
    <SiteSection id='pret-amortissable-classique'>
      <FundingDepreciableLoanFirst />
    </SiteSection>
    <SiteSection id='pret-amortissable-interet'>
      <FundingDepreciableLoanSecond />
    </SiteSection>
    <SiteSection id='pret-relais'>
      <FundingBridgingLoan />
    </SiteSection>
    <SiteSection id='pret-in-fine'>
      <FundingInFineLoan />
    </SiteSection>
    <SiteSection id='pret-aide-accession'>
      <FundingAccessibleLoan />
    </SiteSection>
    <SiteSection id='pret-accession-sociale'>
      <FundingSocialAccessLoan />
    </SiteSection>
    <SiteSection id='pret-conventionné'>
      <FundingAgreedLoan />
    </SiteSection>
    <SiteSection id='pret-taux-zero'>
      <FundingPTZ />
    </SiteSection>
    <LastSiteSection id='pret-folhomee'>
      <FundingFolhomee />
    </LastSiteSection>
    <Footer noImage={true} noForm={true} />
  </>
)

FundingArrangements.propTypes = {
  location: PropTypes.object.isRequired
}

export default FundingArrangements
