import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 72px;
  padding-bottom: 72px;
  padding-right: 24px;
  padding-left: 24px;
`

const Content = styled.div`
  max-width: 790px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ImageBlock = styled.div`
  margin: 40px auto;
`

const FundingInFineLoan = () => (
  <Container>
    <Content>
      <HomeSectionTitle>
        Le prêt <i>In Fine</i> pour un gros investissement immobilier
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Le prêt <i>In Fine</i> s’adresse notamment aux gros investisseurs qui souhaitent bénéficier d’avantages fiscaux.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Ce type de financement immo permet effectivement de déduire davantage d’intérêts d’emprunt des revenus fonciers et de sortir un bien immobilier de l’assiette d’imposition de l’Impôt sur la Fortune Immobilière.
      </HomeSectionDescription>
      <br />
      <HomeSectionDescription>
        Le crédit <i>In Fine</i>, comme son nom l’indique, ne se rembourse qu’à <strong>la fin de l’emprunt</strong>, à savoir généralement après 10, 15 ou 20 ans.
      </HomeSectionDescription>
      <ImageBlock>
        <StaticImage
          src='../../../images/funding-arrangements/in-fine-loan.png'
          alt='Le prêt In Fine pour un gros investissement immobilier'
          fadeIn={false}
          loading='eager'
          width='500' />
       </ImageBlock>
      <HomeSectionDescription>
        Pendant toute la durée de l’emprunt, il s’agit donc de régulariser <strong>uniquement les intérêts</strong> du financement immo auprès de la banque.
      </HomeSectionDescription>
      <br />
      <HomeSectionDescription>
        Au même titre que le crédit relais, le prêt <i>In Fine</i> n’est pas amortissable. Le crédit <i>In Fine</i> est un <strong>type de financement immo particulièrement adapté pour un investissement locatif</strong>. En revanche, le prêt In Fine est intéressant à partir du moment où vous possédez une épargne d’au moins 30 à 50% du capital emprunté.
      </HomeSectionDescription>
    </Content>
  </Container>
)

export default FundingInFineLoan
