import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import LineBreak from '../../atoms/LineBreak'
import Container from '../../atoms/HeroContainer'
import HeroDescription from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 800px;
  margin: auto;
  height: 300px;

  ${media.greaterThan('xl')`
    max-width: 500px;
    margin-right: 55%;
    min-height: 500px;
  `}

  ${media.greaterThan('xxl')`
    max-width: 580px;
    margin-right: 50%;
  `}
`

const Description = styled(HeroDescription)`
  margin-bottom: 16px;
`

const FundingHero = () => (
  <StyledContainer>
    <Header max={100}>
      Quels sont les principaux<LineBreak /> modes de financement immo ?
    </Header>
    <Subtitle>
      Vous souhaitez investir dans un bien et ne connaissez pas forcément tous les modes de financement immo ?
    </Subtitle>
    <Description>
      C’est normal : les types de prêts immobiliers sont nombreux, et tous ne demandent pas de remplir les mêmes conditions. Folhomee est là pour vous aiguiller sur les différentes manières de financer votre futur projet immobilier.
    </Description>
  </StyledContainer>
)

export default FundingHero
