import React from 'react'
import styled from 'styled-components'

import PictoPTZ from '../../../assets/ptz.inline.svg'
import PictoReducedTVA from '../../../assets/reduced-tva.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import PictoColContainer from '../../molecules/PictoColContainer'

const DETAILS_COL = [{
  icon: PictoPTZ,
  title: 'Le PTZ, comme son nom l’indique, vous permet de profiter d’un taux d’emprunt à 0%.',
  description:'Néanmoins, l’emprunt ne couvre pas la totalité du prix du bien immobilier : les pourcentages de prise en charge dépendent effectivement de la zone géographique du bien concerné (4 zones).'
},{
  icon: PictoReducedTVA,
  title: 'Par ailleurs, le Prêt à Taux Zéro peut être cumulé avec la TVA réduite à 5,5% au lieu de 20%.',
  description:'Pour pouvoir profiter de ce complément, il convient bien sûr de remplir certaines conditions.'
}]

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 72px;
  padding-bottom: 72px;
  padding-right: 24px;
  padding-left: 24px;
`

const Content = styled.div`
  max-width: 830px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const FundingPTZ = () => (
  <Container>
    <Content>
      <HomeSectionTitle>
        PTZ, ou Prêt à Taux Zéro pour financer votre projet immobilier
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Le Prêt à Taux Zéro, ou PTZ est le troisième financement d’aide à l’accession.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Pour en bénéficier, vous devez impérativement investir dans de l’immobilier neuf et respecter les conditions de ressources prévues pour ce financement immo.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
    </Content>
  </Container>
)

export default FundingPTZ
