
import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import PhoneFunding from '../../molecules/FundingPhone'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledButton = styled(LaunchButton)`
  margin-top: 15px;

  ${media.greaterThan('md')`
    min-width: 395px;
  `}
`

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => get(theme, 'paleBlue')};
  margin: 0 auto;
  padding: 72px 0;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;

  ${media.lessThan('xl')`
    flex-direction: column;
  `}

  ${media.lessThan('sm')`
    padding: 56px 0;
    gap: 32px;
  `}
`

const StyledCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.greaterThan('xl')`
    max-width:570px;
  `}
`

const RightCol = styled(StyledCol)`
  margin: 0 24px;

  ${media.greaterThan('sm')`
    margin: 0 40px;
  `}

  ${media.greaterThan('md')`
    margin: 0 72px;
  `}

  ${media.greaterThan('xl')`
    margin: 0;
    margin-right: 72px;
  `}
`

const StyledHomeSectionSubtitle = styled(HomeSectionSubtitle)`
  width: 100%;
  margin-bottom: 0;
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  margin-top: 16px;
  
  & > span {
    display: block;
    margin: 16px 0;
    font-weight: 600;
    color: ${({ theme }) => get(theme, 'blue')};
  }
`

const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
`

const DesktopLineBreak = styled.br`
  ${media.lessThan('sm')`
    display: none;
  `}
`

const FundingFolhomee = () => (
  <Container>
    <StyledCol>
      <PhoneFunding />
    </StyledCol>
    <RightCol>
      <HomeSectionTitle>
        Quoi qu’il en soit, acheter un bien immobilier neuf vous permet de jouir de nombreux avantages financiers :
      </HomeSectionTitle>
      <StyledHomeSectionSubtitle>
        Prêt à taux zéro, TVA réduite, frais de notaire réduits, pas de frais d’agence.
      </StyledHomeSectionSubtitle>
      <StyledHomeSectionDescription>
        Par ailleurs, en investissant dans un bien immobilier neuf, votre apport personnel se trouve considérablement réduit.
      </StyledHomeSectionDescription>
      <StyledHomeSectionSubtitle>
        Faites-vous accompagner par Folhomee et ses experts. De la recherche de votre bien immobilier à son ameublement, en passant par son financement, nous vous guidons pas à pas.
      </StyledHomeSectionSubtitle>
      <BottomText>
        <strong>Besoin de renseignements ?</strong><DesktopLineBreak /> Folhomee vous accompagne dans votre projet immobilier.
      </BottomText>
      <ButtonContainer>
        <StyledButton
          link='/programmes-immobiliers-neufs'
          title='Tous les biens immobiliers à vendre sont chez Folhomee'
          background='blue' />
      </ButtonContainer>
    </RightCol>
  </Container>
)

export default FundingFolhomee
