import React from 'react'
import styled from 'styled-components'

import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoReducedFees from '../../../assets/reduced-fees.inline.svg'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoReducedFees,
  title: 'Le prêt d’accession sociale ouvre le droit aux APL et permet de bénéficier de frais réduits (frais de notaire, frais d’agence...)',
  description:'Ce financement immobilier peut être obtenu pour l’achat dans l’ancien ou le neuf, néanmoins, l’achat dans le neuf permet d’avoir des frais annexes encore plus réduits.'
}]

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 72px;
  padding-bottom: 72px;
  padding-right: 24px;
  padding-left: 24px;
`

const Content = styled.div`
  max-width: 790px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const FundingSocialAccessLoan = () => (
  <Container>
    <Content>
      <HomeSectionTitle>
        Le prêt d’accession sociale (PAS)
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Le prêt d’accession sociale est un mode de financement immo accordé sous conditions de ressources.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Ce crédit est accordé aux personnes qui peuvent se permettre de financer la totalité d’un bien immobilier, ainsi que certains frais annexes.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
      <HomeSectionDescription>
        La durée d’emprunt du PAS est comprise <strong>entre 5 et 30 ans</strong> avec des taux d’intérêt qui ne peuvent pas dépasser les plafonds déterminés par décret.
      </HomeSectionDescription>
    </Content>
  </Container>
)

export default FundingSocialAccessLoan

